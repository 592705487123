import React from "react"

import Tabla from "./tabla"
import { useStaticQuery, graphql } from "gatsby"

export default function TablaMain() {
  let data = useStaticQuery(graphql`
    query Admitidos {
      allAirtable(
        filter: { data: { section: { eq: "MuroDeAdmitidos" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            data2
            data3
            data4
          }
        }
      }
    }
  `)

  const unique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  data = data.allAirtable.nodes.filter(node => node.data.Name === "alumno")

  let tablas = []
  data.map(table => {
    tablas.push(table.data.data)
    return null
  })

  tablas = tablas.filter(unique)
  let tablasObj = {}

  tablas.map(tableName => {
    tablasObj[tableName] = {}
    tablasObj[tableName].title = tableName
    tablasObj[tableName].alumno = []
    return null
  })
  data.map(table => {
    tablasObj[table.data.data].alumno.push({
      name: table.data.data2,
      university: table.data.data3,
      score: table.data.data4,
    })
    return null
  })

  return (
    <div>
      {tablas.map(tabla => {
        return <Tabla {...tablasObj[tabla]} />
      })}
    </div>
  )
}
