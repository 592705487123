import React from "react"
import tablaStyles from "./tabla.module.scss"
import Alumno from "./alumuno"

export default function tabla(props) {
  return (
    <div className={tablaStyles.container}>
      <div className={tablaStyles.titleContainer}>
        <h1 className={tablaStyles.title}>{props.title}</h1>
      </div>
      {props.alumno.map(alum => {
        return <Alumno {...alum} />
      })}
    </div>
  )
}
