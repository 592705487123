import React from "react"
import alumnoStyles from "./alumno.module.scss"
import icon from "../../images/14.png"

import { BrowserView, MobileView } from "react-device-detect"

export default function alumno(props) {
  return (
    <div>
      <BrowserView>
        <div className={alumnoStyles.container}>
          <div className={alumnoStyles.textContainer}>
            <div className={alumnoStyles.imgContainer}>
              <img src={icon} className={alumnoStyles.img} alt="icon" />
            </div>
            <p className={alumnoStyles.text}>{props.name}</p>
          </div>
          <div className={alumnoStyles.textContainer}>
            <p className={alumnoStyles.text}>{props.university}</p>
          </div>
          <div className={alumnoStyles.textContainer}>
            <p className={alumnoStyles.text}>{props.score}</p>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={alumnoStyles.container}>
          <div className={alumnoStyles.textContainer}>
            <div className={alumnoStyles.imgContainer}>
              <img src={icon} className={alumnoStyles.img} alt="icon" />
            </div>
            <p className={alumnoStyles.text}>{props.name}</p>
          </div>
          <div className={alumnoStyles.textContainerMobile}>
            <p className={alumnoStyles.text}>
              <strong>{props.university}</strong>
            </p>
            <p className={alumnoStyles.text}>{props.score}</p>
          </div>
        </div>
      </MobileView>
    </div>
  )
}
