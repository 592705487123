import React from "react"
import Layout from '../components/layout/layout'
import HeroWithTitle from "../components/muro-de-admitidos/heroWithTitle"
import Tabla from "../components/muro-de-admitidos/tablaMain"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Students"
      description="Estos son alumnos y sus puntuaciones en los examenes de admición."
      // image={`https://www.ppdc.mx${blog}`}
      url="/muro-de-admitidos"
    />
    <HeroWithTitle />
    <Tabla />
  </Layout>
)
