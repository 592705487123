import React from "react"
import strechedHeroStyles from "./heroWithTitle.module.scss"
import imgHero from "../../images/heroAdmitidos.png"

export default function strechedHeroWithTitle() {
  return (
    <div className={strechedHeroStyles.container}>
      <div className={strechedHeroStyles.imgContainer}>
        <img src={imgHero} className={strechedHeroStyles.img} alt="heroStudy" />
      </div>
      <div className={strechedHeroStyles.textContainer}>
        <h1 className={strechedHeroStyles.title}>
          ORGULLOSOS DE NUESTRO MURO DE ADMITIDOS
        </h1>
      </div>
    </div>
  )
}
